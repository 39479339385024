import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/common.css';
import '../assets/css/about.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';

const About = () => {
	return <>
		<div className="container">
		<div className="row content">
			<div className="section-title">
				<h2>About Us</h2>
		 	</div>
		</div>

		<div className="row content">
		<div className="col-lg-6 pt-4 pt-lg-0" style={{"text-align":"center"}}>
				<img src="images/team.png" alt="EaseCorps" title="EaseCorps" style={{"max-width":"100%"}}/>
		    </div>
		<div className="col-lg-6 pt-4 pt-lg-0">
		    <p>EaseCorps Staffing Solution Pvt. Ltd. is one of the fastest growing service companies in India. It caters to corporate clients, providing them with efficient manpower for their manifold recruitment requirements. Human resource solutions is the reputed forte of EaseCorps Staffing Solution Pvt. Ltd. and we do our best to supply myriad staffing assistance like Contract Staffing Services, Permanent Staffing Services, Remote/ Virtual IT Staffing Services, Offshore Staffing Services, RPO Services as well as Onsite Recruitment Services in India as well as countries across the world.</p>

			<p>We met a growing demand to deliver a managed Recruitment Solution working closely with organizations to offer cost and time saving, skilled Staff and freedom from the administrative burden as we have a huge data-base of Consultants since our Recruitment team are working on it. Since this time, the company has earned a leading reputation for delivering the finest managed Staffing & Outsourcing Services to Private Organizations.</p>

		    <p>We have Recruitment team who are highly qualified and experienced enough to close positions on target basis. Our staffing solutions are unparalleled in India, being specifically intended to suit your desired qualifications of applicants as per your set budget  Our database include only the verified and adept job seekers those who have a skill set in accordance with the current industry trends. The services that RecruitSteps takes pride in include.</p>

			<p>We offer wide wide variety of staffing solutions to corporate companies for their IT and non-IT recruitments in Noida, Bangalore, Ahmedabad, Jaipur, Kolkata and to many other major cities. Companies in USA, UK too benefit greatly, from our unique industry- focused services</p>
		  
		  </div>
		</div>

      </div>
	<section id="services" className="services">
		<div className="container">
			<div className="section-title">
			  <h2>Our Services</h2>
			</div>
			<div className="row">

			  <div className="col-md-6 mt-4 mt-md-0">
			    <div className="icon-box">
			      <i className="bi bi-people-fill"></i>
			      <h4><a href="#">Temporary staffing/Contract staffing</a></h4>
			      <p>Bridging ambition and opportunity with flexible pathways to permanent employment</p>
			    </div>
			  </div>
			 
			  <div className="col-md-6 mt-4 mt-md-0">
			    <div className="icon-box">
			      <i className="bi bi-bar-chart"></i>
			      <h4><a href="#">Permanent Staffing</a></h4>
			      <p>Building lasting success by connecting companies with enduring talent</p>
			    </div>
			  </div>

			<div className="col-md-6 mt-4 mt-md-0">
			    <div className="icon-box">
			      <i className="bi bi-person-vcard"></i>
			      <h4><a href="#">Offshore staffing services</a></h4>
			      <p>Expand your team's horizon with elite offshore staffing services - talent without borders</p>
			    </div>
			  </div>
			
			<div className="col-md-6 mt-4 mt-md-0">
			    <div className="icon-box">
			      <i className="bi file-earmark-richtext"></i>
			      <h4><a href="#">RPO services</a></h4>
			      <p>Revolutionize your recruitment: RPO services - where strategy meets talent.</p>
			    </div>
			  </div>

			<div className="col-md-6 mt-4 mt-md-0">
			    <div className="icon-box">
			      <i className="bi bi-person-bounding-box"></i>
			      <h4><a href="#">Remote/ Virtual staffing services</a></h4>
			      <p>Elevate productivity, flexibility, and efficiency with virtual staffing solutions</p>
			    </div>
			  </div>

			</div>
		</div>
	</section>
	</>

}

export default About
