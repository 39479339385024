import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/common.css';

const Footer = () => {
	return <footer id="footer">
		<div className="container">
		      <div className="copyright">
			</div>
		</div>
	</footer>	
}

export default Footer;
