import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/common.css';
import '../assets/css/about.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';

const Contact = () => {
	return <>
		<div className="container">
			<div className="section-title">
			  <h2>Contact Us</h2>
			</div>

			<div className="row mt-5">

			  <div className="col-lg-4">
			    <div className="info">
			      <div className="address">
				<i className="bi bi-geo-alt"></i>
				<h4>Location:</h4>
				<p>Sector 18, Noida, IN 201301,</p>
				<p>Adam Street, New York, NY 535022</p>
			      </div>

			      <div className="email">
				<i className="bi bi-envelope"></i>
				<h4>Email:</h4>
				<p>info@easecorps.com</p>
			      </div>

			      </div>

			  </div>
			

			<div className="col-lg-8 mt-5 mt-lg-0">

			    <form action="forms/contact.php" method="post" role="form" className="php-email-form">
			      <div className="row">
				<div className="col-md-6 form-group">
				  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
				</div>
				<div className="col-md-6 form-group mt-3 mt-md-0">
				  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
				</div>
			      </div>
			      <div className="form-group mt-3">
				<input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
			      </div>
			      <div className="form-group mt-3">
				<textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
			      </div>
			      <div className="my-3">
				<div className="loading">Loading</div>
				<div className="error-message"></div>
				<div className="sent-message">Your message has been sent. Thank you!</div>
			      </div>
			      <div className="text-center"><button type="submit">Send Message</button></div>
			    </form>

			  </div>
		</div>
		</div>
	</>
}

export default Contact;
